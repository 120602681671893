// react
import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
// mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Skeleton,
  Checkbox,
  FormControlLabel,
  Grid
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// redux
import { useSelector } from 'react-redux';

// notistack
import { useSnackbar } from 'notistack';

// project constants
import { affiliateBookingStatuses, bookingStatuses } from 'constants/bookings';
import validContactMethods from 'constants/validContactMethods';
import userTypes from 'constants/userTypes';

// project utils
import { getEntityName, useGetEntityName } from 'utils/entities';
import handleError from 'utils/handle-error';
import callAzureFunction from 'utils/call-azure-function';
import AddMultipleEmails from 'ui-component/AddMultipleEmails';
import useAppDataSource from 'hooks/useAppDataSource';

//= =============================|| ViewBookingDialog ||==============================//

const EmailBookingDialog = ({ open, setOpen, setViewDialogOpen, bookingId = false }) => {
  const appConfig = useSelector((state) => state.appConfig?.data);
  const user = useSelector((state) => state.user.data);

  const { appDataSourceId } = useAppDataSource();

  const [emailRecipients, setEmailRecipients] = useState([]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [booking, setBooking] = useState({});
  const [loadingBooking, setLoadingBooking] = useState(true);
  const [sendingReminder, setSendingReminder] = useState(false);
  const [sendSmsReminder, setSendSmsReminder] = useState(false);
  const bookingEntityName = useGetEntityName('booking');
  const respondentEntityName = getEntityName('respondent', appConfig);
  const adminEntityName = getEntityName('admin', appConfig);
  const { Respondent: respondent, affiliateSelected, status } = booking;

  const bookingStatus = useMemo(() => {
    let bookingStatus;
    if (affiliateSelected) {
      bookingStatus = Object.values(affiliateBookingStatuses).find((bs) => bs.value === status);
    }
    if (!affiliateSelected || !bookingStatus) {
      bookingStatus = Object.values(bookingStatuses).find((bs) => bs.value === status);
    }
    return bookingStatus;
  }, [affiliateSelected, status]);

  const handleClose = () => {
    setEmailRecipients([]);
    setOpen(false);
    setViewDialogOpen(true);
    closeSnackbar();
  };

  const emailAlreadyExists = (email) => emailRecipients.includes(email);

  const findKeyForEmailAddress = (newEmail) => emailRecipients.findIndex((email) => email === newEmail);

  const removeEmailRecipient = (key) => {
    setEmailRecipients((emailRecipients) => emailRecipients.filter((_, i) => i !== key));
  };

  const removeBookingRespondentEmail = (email) => {
    const key = findKeyForEmailAddress(email);
    if (key >= 0) {
      removeEmailRecipient(key);
    }
  };

  const handleSendRequest = async () => {
    const snackbarProps = {
      variant: 'info',
      message: ''
    };

    const respondentEmailAddress = respondent.emailAddress;

    try {
      setSendingReminder(true);

      const sendEmailReminder = emailAlreadyExists(respondentEmailAddress);

      // send confirmation via email and SMS (if checked)
      if (sendEmailReminder || sendSmsReminder) {
        await callAzureFunction({
          url: `/bookings/${booking.id}/send-confirmation`,
          method: 'post',
          data: {
            bookingId,
            recipients: emailRecipients,
            contactMethods: appConfig?.generalSettings.sendBySmsEnabled
              ? [validContactMethods.Email, validContactMethods.SMS]
              : [validContactMethods.Email],
            emailSubject: appConfig?.reminderBookingText.emailSubject,
            emailBody: appConfig?.reminderBookingText.emailBody,
            smsBody: appConfig?.reminderBookingText.smsBody,
            emailConfirmationLogo: appConfig?.images.emailConfirmationLogo,
            selectedContactMethods: { emailAddress: sendEmailReminder, contactMobilePhone: sendSmsReminder },
            forceSend: true
          },
          appDataSourceId
        });
      }

      // Remove recipient email from email recipient array. They don't need to get the confirmation email
      const confirmationEmailRecipients = emailRecipients.filter((recipient) => recipient !== respondentEmailAddress);

      if (confirmationEmailRecipients.length > 0) {
        const promises = confirmationEmailRecipients.map(async (recipient) => {
          await callAzureFunction({
            url: `bookings/${bookingId}/send-confirmation-to-target-email`,
            method: 'post',
            data: {
              entityNames: { respondent: respondentEntityName, booking: bookingEntityName },
              emailAddress: recipient,
              showAppointmentEndTime: false
            },
            appDataSourceId
          });
        });

        await Promise.all(promises);
      }
      snackbarProps.variant = 'success';
      snackbarProps.message = `Reminder ${emailRecipients.length <= 1 ? 'notification has' : 'notifications have'} been sent.`;
      setViewDialogOpen(false);
    } catch (error) {
      snackbarProps.variant = 'error';
      snackbarProps.message = 'Failed to send reminder notification.';
    } finally {
      enqueueSnackbar({
        variant: snackbarProps.variant,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        message: snackbarProps.message
      });
      setSendingReminder(false);
      setEmailRecipients([]);
      setSendSmsReminder(false);
    }
  };

  const addRemoveReminderEmailFromRecipients = () => {
    if (emailAlreadyExists(respondent.emailAddress)) {
      removeBookingRespondentEmail(respondent.emailAddress);
    } else {
      setEmailRecipients([...emailRecipients, respondent.emailAddress]);
    }
  };

  const getBooking = useCallback(async () => {
    try {
      setLoadingBooking(true);
      const response = await callAzureFunction({ url: `/bookings/${bookingId}`, method: 'get', appDataSourceId });
      setBooking(response.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingBooking(false);
    }
  }, [bookingId, appDataSourceId]);

  useEffect(() => {
    if (open && bookingId) {
      getBooking();
    }
  }, [open, bookingId, getBooking]);

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose}>
        {loadingBooking ? (
          <DialogContent>
            <Skeleton variant="rectangular" height={250} />
          </DialogContent>
        ) : (
          <>
            {booking.id ? (
              <>
                <DialogTitle variant="h3">
                  {bookingEntityName} for {respondent ? `${respondent.firstName} ${respondent.lastName}` : '-'}
                </DialogTitle>
                <DialogContent>
                  <Grid container>
                    {respondent && (
                      <>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="emailReminder"
                                checked={emailAlreadyExists(respondent.emailAddress)}
                                onChange={() => addRemoveReminderEmailFromRecipients()}
                              />
                            }
                            label={`Send reminder notification via email to ${respondent.firstName} ${respondent.lastName}`}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="smsReminder"
                                checked={sendSmsReminder}
                                onChange={(event) => setSendSmsReminder(event.target.checked)}
                                disabled={!respondent.contactMobilePhone || !appConfig?.generalSettings.sendBySmsEnabled}
                              />
                            }
                            label={`Send reminder notification via SMS to ${respondent.firstName} ${respondent.lastName}`}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <AddMultipleEmails recipients={emailRecipients} setRecipients={setEmailRecipients} />
                    </Grid>
                    {respondent?.emailAddress && (
                      <Grid item xs={12} mt={2}>
                        <Typography>
                          Note that <strong>{respondent.emailAddress}</strong> will receive a reminder notification if email option is
                          checked. Whereas all other email recipients will receive the {adminEntityName} notification.
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  {/* send booking reminder */}
                  {user.type === userTypes.Admin && bookingStatus?.value === bookingStatuses.Upcoming.value && (
                    <LoadingButton
                      loading={sendingReminder}
                      onClick={handleSendRequest}
                      disabled={emailRecipients.length <= 0 && !sendSmsReminder}
                    >
                      Send
                    </LoadingButton>
                  )}
                  {/* close dialog */}
                  <Button variant="contained" onClick={handleClose} disabled={sendingReminder}>
                    Close
                  </Button>
                </DialogActions>
              </>
            ) : (
              <Typography>{bookingEntityName} not found</Typography>
            )}
          </>
        )}
      </Dialog>
    </>
  );
};

EmailBookingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setViewDialogOpen: PropTypes.func.isRequired,
  bookingId: PropTypes.string.isRequired
};

export default EmailBookingDialog;
