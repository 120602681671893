// axios
import axios from 'axios';

const functionConfig = {
  baseURL: import.meta.env.VITE_APP_AZ_FUNCTIONS_BASE_URI,
  withCredentials: true
};

/**
 * Makes a request to an azure function without user authentication.
 * @param {object} requestConfig Axios request config. Only the `url` is required. For all available options see https://axios-http.com/docs/req_config.
 */
const callAzureFunctionPublic = async (requestConfig) => {
  // Destructure appDataSourceId and other params from requestConfig
  const { appDataSourceId, params = {}, ...axiosConfig } = requestConfig;

  // Add appDataSourceId to params if it is provided and not 'default'
  const updatedParams = {
    ...params,
    ...(appDataSourceId && appDataSourceId !== 'default' ? { appDataSourceId } : {})
  };

  return axios({
    ...axiosConfig,
    params: updatedParams, // use updated params with optional appDataSourceId
    ...functionConfig
  });
};

export default callAzureFunctionPublic;
