import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Menu, MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { DATA_SOURCES } from 'constants/appDataSources';
import { setAppDataSourceId } from 'store/features/appDataSourceSlice';
import useAppDataSource from 'hooks/useAppDataSource';

const DataSourceMenu = () => {
  const dispatch = useDispatch();
  const { appDataSourceId, appDataSourceVisible, appDataSourceDisabled, useGetAppDataSources } = useAppDataSource();
  const appDataSources = useGetAppDataSources();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [dataSources, setDataSources] = useState(Object.values(DATA_SOURCES));
  useEffect(() => {
    setDataSources(Object.values(appDataSources));
  }, [appDataSources]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeDataSource = (source) => {
    dispatch(setAppDataSourceId(source.id));
    handleClose();
  };

  return (
    <>
      {appDataSourceVisible && dataSources.length > 1 ? (
        <div>
          <Button
            id="data-source-button"
            aria-controls={open ? 'data-source-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleOpen}
            endIcon={<ExpandMoreIcon />}
            disabled={appDataSourceDisabled}
          >
            <span>{dataSources.find((source) => source.id === appDataSourceId)?.name || 'N/A'}</span>
          </Button>
          <Menu
            id="data-source-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'data-source-button'
            }}
          >
            {dataSources.map((source) => (
              <MenuItem key={source.id} onClick={() => handleChangeDataSource(source)} selected={source.id === appDataSourceId}>
                {source.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
      ) : null}
    </>
  );
};

export default DataSourceMenu;
