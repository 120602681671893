// react
import PropTypes from 'prop-types';
import { Fragment } from 'react';

// mui
import { Typography, List, Card } from '@mui/material';

// project imports
import { useGetEntityName } from 'utils/entities';
import { calendarEventTypes } from 'constants/calendar';
import dayjs from 'dayjs';

const BookingsCollisionDialog = ({ bookings, enableMobileBookingMode }) => {
  const managerEntityName = useGetEntityName('manager');
  const respondentEntityName = useGetEntityName('respondent');

  return (
    <List dense>
      <Typography gutterBottom variant="h5" sx={{ mb: 2 }}>
        {enableMobileBookingMode
          ? `This mobile service provider is in conflict with the following events:`
          : `One or more collisions have been detected with the following:`}
      </Typography>
      {bookings.map((booking, index) => {
        const startTime = booking.start instanceof dayjs ? booking.start.format('HH:mm a') : dayjs(booking.start).format('HH:mm a');
        const endTime = booking.end instanceof dayjs ? booking.end.format('HH:mm a') : dayjs(booking.end).format('HH:mm a');

        const jsx = {
          [calendarEventTypes.booking]: (
            <Fragment key={`booking-card-${index}`}>
              <Typography variant="h4" gutterBottom>{`Booking ${index + 1}`}</Typography>
              <Card style={{ border: '1px solid black', marginBottom: '20px', padding: '10px' }}>
                {booking?.id && (
                  <Typography>
                    <b>ID: </b>
                    {booking.id}
                  </Typography>
                )}
                <Typography>
                  <b>Time: </b>
                  {`${startTime} - ${endTime}`}
                </Typography>
                {booking?.services && (
                  <Typography>
                    <b>Service: </b>
                    {booking.services.join(', ')}
                  </Typography>
                )}
                {booking?.manager && (
                  <Typography>
                    <b>{`${managerEntityName}: `}</b>
                    {booking.manager}
                  </Typography>
                )}
                {booking?.respondent && (
                  <Typography>
                    <b>{`${respondentEntityName}: `}</b>
                    {booking.respondent}
                  </Typography>
                )}
              </Card>
            </Fragment>
          ),
          [calendarEventTypes.blockedSlot]: (
            <Fragment key={`location-event-${index}`}>
              <Typography variant="h4" gutterBottom>
                {booking.eventName ? booking.eventName : `Location event ${index + 1}`}
              </Typography>
              <Card style={{ border: '1px solid black', marginBottom: '20px', padding: '10px' }}>
                <Typography>
                  <b>Time: </b>
                  {`${startTime} - ${endTime}`}
                </Typography>
              </Card>
            </Fragment>
          ),
          [calendarEventTypes.managerBlockedSlot]: (
            <Fragment key={`manager-at-location-event-${index}`}>
              <Typography variant="h4" gutterBottom>
                {booking.eventName ? booking.eventName : `${managerEntityName} event ${index + 1}`}
              </Typography>
              <Card style={{ border: '1px solid black', marginBottom: '20px', padding: '10px' }}>
                <Typography>
                  <b>Time: </b>
                  {`${startTime} - ${endTime}`}
                </Typography>
              </Card>
            </Fragment>
          ),
          [calendarEventTypes.outlookCalendar]: (
            <Fragment key={`booking-card-${index}`}>
              <Typography variant="h4" gutterBottom>{`Outlook Event ${index + 1}`}</Typography>
              <Card style={{ border: '1px solid black', marginBottom: '20px', padding: '10px' }}>
                <Typography>
                  <b>Time: </b>
                  {`${startTime} - ${endTime}`}
                </Typography>
                <Typography>
                  <b>Subject: </b>
                  {booking.subject}
                </Typography>
                <Typography>
                  <b>{`${managerEntityName}: `}</b>
                  {booking.manager}
                </Typography>
              </Card>
            </Fragment>
          ),
          [calendarEventTypes.bufferTimeConstraint]: (
            <Fragment key={`booking-card-${index}`}>
              <Typography variant="h4" gutterBottom>{`Travel Time Duration ${index + 1}`}</Typography>
              <Card style={{ border: '1px solid black', marginBottom: '20px', padding: '10px' }}>
                <Typography>
                  <b>Time: </b>
                  {`${startTime} - ${endTime}`}
                </Typography>
                <Typography>
                  <b>{`${managerEntityName}: `}</b>
                  {booking.manager}
                </Typography>
                <Typography>
                  <b>{`${respondentEntityName}: `}</b>
                  {booking.respondent}
                </Typography>
                <Typography>
                  <b>Travel From/To: </b>
                  {booking.mobileBookingClientAddress}
                </Typography>
              </Card>
            </Fragment>
          )
        };

        return jsx[booking.type];
      })}
    </List>
  );
};

BookingsCollisionDialog.propTypes = {
  bookings: PropTypes.array,
  enableMobileBookingMode: PropTypes.bool
};

export default BookingsCollisionDialog;
